<template>

  <div class="payments-suc mt-5">
    <div class="content-box">
      <div class="bs p-5" style="width: 90%;background: #ffffff; height: 875px;">
        <h2>支付结果</h2>
        <el-divider></el-divider>

<!--        订单支付页面-->
        <div>
        <div class="uf uf-ac">
          <img src="https://zhichan.gongsibao.com/imgs/icon-payform.png" alt="" style="width: 60px;height: 60px;">
          <div class="ml-5">
            <div class="f22 fwb ">订单支付成功，付款金额：<span class="c-red">￥{{this.$route.params.totalSum}}</span></div>
            <div class="uf uf-ac mt-3 ">
              <div>
                <span class="f14 c-base">订单编号：</span>
                <span>{{this.$route.params.orderNo}}</span>
              </div>
              <div>
                <span class="f14 c-base ml-2">服务项目：</span>
                <span>{{this.$route.params.serviceName}}</span>
              </div>
            </div>
          </div>

        </div>
            <div style="text-align:center; margin:60px auto">
              <img src="../../assets/img/payment-success.png" style="text-align:center; width:400px ; height: 400px;">
            </div>
        <el-divider></el-divider>
        </div>
            <div style="margin-left: 24vw;">
                <el-button type="primary" :loading="false" @click="goMyOrder">查看订单</el-button>
                <el-button type="primary" :loading="false" @click="goHomePage">返回首页</el-button>
            </div>

      </div>

    </div>

  </div>
</template>

<script>
export default {

    data() {
        return {
            totalSum:'',
            orderNo:'',
            serviceName:'',
        }
    },
    methods: {
        goback() {
          this.$router.push({name : 'my-order'})
      },
        goHomePage() {
        this.$router.push({name:'main'})
      },
        goMyOrder() {
            this.$router.push({name:'my-order'})
        }
    },
}
</script>

<style>
  .payments-suc .content-box {
    width: 900px;
    margin: 20px auto;
  }
</style>
